<template>
  <div>
     <Loader v-if="(!filteredGroups || filteredGroups.length == 0)" />

    <div class="searchBox mb-3 flex justify-space-between align-center">
      <input type="text" placeholder="Search Job Title or Keyword" v-model="searchText" id="search" name="searchText" style="padding:1.2rem; line-height:1.4;" />
      <button class="btn btn__dark ml-3 mt-0" style="min-height:44px;" v-show="this.searchText" @click="clearSearch()">Clear</button>
    </div>

    <!-- <div class="dashboard__container--body pt-3" v-if="userProfile && !userProfile.evereeOnboardingComplete">
      <div class="mt-3" >
        <div class="mb-3">You must complete your payroll onboarding before joining any groups.</div>
        <a href="/account/payroll/home">
          <button class="btn btn__large btn__primary">Payroll Setup</button>
        </a>
      </div>
    </div> -->

    <div class="dashboard__container--body pt-3" v-if="(filteredGroups && filteredGroups.length >= 1)">
      <div class="dashboard__container--body--col flex" v-for="(shift, index) in sortedShiftsByProximity" :key="index">
        <ShiftCard :shift="shift" :showLink="true" />
      </div>
    </div>

  </div>
</template>

<style scoped>
  .searchBox {
    width:calc(50% - 1.6rem);
  }
  @media only screen and (max-width: 600px) {
    .searchBox {
      width:100%;
      margin-left:0;
    }
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ShiftCard from '@/components/Shifts/ShiftCard.vue'
const fb = require('../../firebaseConfig.js')


export default {
  name: 'availableShifts',
  data: () => ({
    lat: 0,
    lng: 0,
    searchText: '',
    dateSort: true,
  }),
  computed: {
    ...mapState(['userProfile', 'currentUser', 'availableShiftsWithoutGroups', 'userAvailableData']),
    // filteredGroups() {
    //   if (!this.searchText) {
    //     return this.availableShiftsWithoutGroups
    //   } else {
    //     return this.availableShiftsWithoutGroups.filter(item => {
    //     return ((
    //       item.location.toLowerCase().includes(this.searchText.toLowerCase()) || 
    //       item.event.toLowerCase().includes(this.searchText.toLowerCase()) || 
    //       item.position.title.toLowerCase().includes(this.searchText.toLowerCase()) || 
    //       item.position.description.toLowerCase().includes(this.searchText.toLowerCase())
    //       ))
    //       // item.position.some(position => position.title.toLowerCase().includes(this.searchText.toLowerCase())) || 
    //       // item.position.some(position => position.description.toLowerCase().includes(this.searchText.toLowerCase())) ))
    //     })
    //   }
    // },
    filteredGroups() {
      if (!this.searchText) {
        return this.availableShiftsWithoutGroups
      } else {
        return this.availableShiftsWithoutGroups.filter(item => {

          // let jobTitles = []

          // if (item.job && item.job.length >= 1) {
          //   item.job.forEach(item => {
          //     if (item.title) {
          //       jobTitles.push(item.title)
          //     }
              
          //   })
          // }
          const computedObj = { ...item,
          description: item.description,
          job: item.position.title,
          event: item.event,
          city: item.address.city,
          venue: item.venue,
          description: item.position.description,
        }
        return Object.keys(computedObj)
          .some(key => ('' + computedObj[key]).toLowerCase().includes(this.searchText.toLowerCase()))
        // return (
        //   item.title.toLowerCase().includes(this.searchText.toLowerCase()) || item.location.toLowerCase().includes(this.searchText.toLowerCase()) || item.description.toLowerCase().includes(this.searchText.toLowerCase()) || item.job.some(job => job.title.toLowerCase().includes(this.searchText.toLowerCase())))
        })
      }

    },
    // allAvailableShifts() {
    //   if ((this.availableShifts && this.availableShifts.length >= 1) || (this.availableShiftsWithoutGroups && this.availableShiftsWithoutGroups.length >= 1)) {
    //   return [...this.availableShifts, ...this.availableShiftsWithoutGroups]
    //   } else {
    //     return null
    //   }
    // },
    // uniqueShifts() {
    //   if (this.allAvailableShifts && this.allAvailableShifts.length >= 1) {
    //     return Array.from(new Set(this.allAvailableShifts.map(a => a.id)))
    //      .map(id => {
    //        return this.allAvailableShifts.find(a => a.id === id)
    //      })
    //    } else {
    //     return null
    //    }
    // },

    // sortedShifts() {
    //   if (this.uniqueShifts && this.uniqueShifts.length >= 1) {
    //     return this.uniqueShifts.sort((a, b) => {
    //       return Date.parse(a.day) - Date.parse(b.day);
    //     })
    //   } else {
    //     return null
    //   }
    // },
    coordinates() {
      return `${this.lat}, ${this.lng}`;
    },
    sortedShiftsByProximity() {
      if (this.lat == '0' || this.dateSort) {
        return this.filteredGroups
      } else if (this.lat != '0' && this.dateSort) {
        return this.filteredGroups
      } else if (this.lat != '0' && !this.dateSort) {
        return this.resultsByProximity()
      } 
    }
  },
  created() {
    this.locatorButtonPressed()
    // if (!this.availableShiftsWithoutGroups || this.availableShiftsWithoutGroups.length == 0) {
      
    // }
    this.$store.dispatch("getAvailableShiftsWithoutGroups")
  },
  mounted() {
    this.resultsByProximity()
  },
  methods: {
    clearSearch() {
      this.searchText = ''
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
         position => {
            this.lat = (position.coords.latitude || 39.1031182);
            this.lng = (position.coords.longitude || -84.5120196);
          },
         error => {
           console.log(error.message);
         },
      )
    },
    resultsByProximity(){
      this.performingRequest = true
      if (this.lat == 0) {
        return this.locatorButtonPressed()
      }
      const asin = Math.asin
      const cos = Math.cos
      const sin = Math.sin
      const PI_180 = Math.PI / 180

      function hav(x) {
        const s = sin(x / 2)
        return s * s
      }

      function relativeHaversineDistance(lat1, lon1, lat2, lon2) {
        const aLatRad = lat1 * PI_180
        const bLatRad = lat2 * PI_180
        const aLngRad = lon1 * PI_180
        const bLngRad = lon2 * PI_180

        const ht = hav(bLatRad - aLatRad) + cos(aLatRad) * cos(bLatRad) * hav(bLngRad - aLngRad)
        return asin(ht)
      }
      const sorted = this.filteredGroups.sort((a, b) => relativeHaversineDistance(a.center.lat, a.center.lng, this.lat, this.lng) - relativeHaversineDistance(b.center.lat, b.center.lng, this.lat, this.lng))
      this.performingRequest = false
      return sorted
      
    },
  },
  components: {
    Loader,
    ShiftCard
  },
}
</script>